import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { map } from 'rxjs';
import { AppRoute } from '../app-route';
import { AppContext } from '../app-state/app.models';
import { AppState } from '../app-state/app.state';
import { AuthState } from './auth.state';

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const store = inject(Store);
  const router = inject(Router);

  return store.select(AuthState.loggedIn).pipe(
    map((loggedIn) => {
      if (loggedIn) return true;

      const redirectTo =
        store.selectSnapshot(AppState.context) === AppContext.ConnectExtension
          ? AppRoute.ConnectPermissionDenied
          : AppRoute.Login;

      return router.createUrlTree([redirectTo], { queryParams: { returnPath: state.url } });
    }),
  );
};

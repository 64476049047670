import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  CUSTOM_ELEMENTS_SCHEMA,
  effect,
  inject,
  signal,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { isDefined, isNil } from '@trimble-gcs/common';
import {
  ModusButtonModule,
  ModusIconModule,
  ModusSelectModule,
  ModusTooltipModule,
} from '@trimble-gcs/modus';
import { filter, map, Observable, shareReplay, take } from 'rxjs';
import { LoadingService } from '../../../loading/loading.service';
import { ImportFile } from '../../import.models';
import { ClarityFileBrowserComponent } from './clarity-file-browser/clarity-file-browser.component';
import { ClarityTreeItem, ClarityUserWorld } from './clarity.models';
import { ClarityService } from './clarity.service';

interface WorldView {
  id: number;
  name: string;
  world: ClarityUserWorld;
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ModusTooltipModule,
    ModusIconModule,
    ModusButtonModule,
    ModusSelectModule,
    MatDialogModule,
    MatProgressBarModule,
    ClarityFileBrowserComponent,
    ReactiveFormsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './clarity-file-picker.component.html',
  styles: [
    `
      :host {
        height: 100%;
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClarityFilePickerComponent {
  private readonly loadingService = inject(LoadingService);
  private readonly clarityService = inject(ClarityService);

  worldOptions = toSignal(this.getWorldOptions());
  worldControl = new FormControl<WorldView | null>(null);

  private worldControlChanges = toSignal(this.worldControl.valueChanges);
  selectedWorld = computed(() => this.worldControlChanges()?.world);

  selectedItems = signal<ClarityTreeItem[]>([]);
  selectedSummary = computed(() => this.getSelectedSummary());
  selectedSummaryTooltip = computed(() =>
    this.selectedItems()
      .map((item) => item.name)
      .join('\n'),
  );

  isLoading = toSignal(this.loadingService.isLoading$(this));

  disableCancel = signal(false);
  disablePicking = computed(() => this.isLoading() || this.disableCancel());
  disableNext = computed(() => this.disableCancel() || this.selectedItems().length === 0);

  constructor(private dialogRef: MatDialogRef<ClarityFilePickerComponent, ImportFile[]>) {
    this.createWorldOptionsEffect();
    this.selectDefaultWorld();
  }

  setLoading(loading: boolean) {
    if (loading) this.loadingService.startLoading(this);
    else this.loadingService.stopLoading(this);
  }

  fileClicked(treeItem: ClarityTreeItem) {
    const selectedTreeItems = this.selectedItems();
    const foundFile = selectedTreeItems.find((item) => item.id === treeItem.id);

    // add or remove tree item
    const newSelectedTreeItems = isNil(foundFile)
      ? [...selectedTreeItems, treeItem]
      : [...selectedTreeItems.filter((item) => item.id !== treeItem.id)];

    this.selectedItems.set(newSelectedTreeItems);
  }

  cancelClick() {
    this.dialogRef.close();
  }

  nextClick() {
    this.loadingService.startLoading(this);
    this.disableCancel.set(true);
    this.worldControl.disable();

    this.clarityService
      .mapToImportFiles(this.selectedItems())
      .subscribe((importFiles) => this.dialogRef.close(importFiles));
  }

  private createWorldOptionsEffect() {
    effect(() => {
      if (isNil(this.worldOptions())) {
        this.worldControl.disable();
      } else {
        this.worldControl.enable();
      }
    });
  }

  private selectDefaultWorld() {
    // set initial world value
    toObservable(this.worldOptions)
      .pipe(
        filter((worlds) => isDefined(worlds)),
        take(1),
      )
      .subscribe((worlds) => this.worldControl.setValue(worlds.at(0) ?? null));
  }

  private getWorldOptions(): Observable<WorldView[]> {
    const getWorlds$ = this.clarityService.getWorlds().pipe(
      map((worlds) => {
        return worlds.map((world) => {
          const name = isDefined(world.entitlement.accountName)
            ? `${world.name} (${world.entitlement.accountName})`
            : world.name;

          return {
            id: world.id,
            name,
            world,
          } satisfies WorldView;
        });
      }),
      map((worldViews) => worldViews.toSorted((a, b) => a.name.localeCompare(b.name))),
    );

    return this.loadingService
      .loadFrom(getWorlds$, this)
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));
  }

  private getSelectedSummary() {
    const selectedCount = this.selectedItems().length;
    if (selectedCount === 0) return;

    return `${selectedCount} file${selectedCount > 1 ? 's' : ''} selected`;
  }
}

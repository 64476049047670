import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { EMPTY, catchError, from, retry, switchMap, throwError } from 'rxjs';
import { AppRoute } from '../app-route';
import { AppContext, Endpoints } from '../app-state/app.models';
import { AppState } from '../app-state/app.state';
import { ConnectService } from '../connect/connect.service';
import { injectLogger } from '../logging/logger-injection';
import { ClearAuth } from './auth.actions';
import { AuthState } from './auth.state';

const MAX_RETRY_ATTEMPTS = 2;

export const tokenInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  if (!isProtected(request.url)) return next(request);

  const store = inject(Store);
  const context = store.selectSnapshot(AppState.context);

  return context === AppContext.ConnectExtension
    ? handleRequestAndRetryOn401(request, next)
    : handleRequestAndLoginOn401(request, next);
};

function isProtected(url: string) {
  const store = inject(Store);
  const endpoints = store.selectSnapshot(AppState.endpoints);
  for (const key in endpoints) {
    const endpoint = endpoints[key as keyof Endpoints];
    if (url.startsWith(endpoint.url)) return endpoint.protected;
  }
  return false;
}

const handleRequestAndRetryOn401: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const store = inject(Store);
  const connectService = inject(ConnectService);
  const logger = injectLogger('TokenInterceptor');
  let retryAttempt = 0;

  const retryable = store.selectOnce(AuthState.accessToken).pipe(
    switchMap((token) => {
      request = addToken(request, token);
      logger.debug(
        `handleRequestAndRetryOn401 [${retryAttempt++}] ~ ${request.url.split('?')[0]}`,
        request,
      );
      return next(request);
    }),
    retry({
      delay: (error, retryCount) => {
        return isUnauthorized(error) && retryCount < MAX_RETRY_ATTEMPTS
          ? from(connectService.getConnectToken())
          : throwError(() => error);
      },
    }),
  );

  return retryable;
};

const handleRequestAndLoginOn401: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const store = inject(Store);
  const router = inject(Router);
  const logger = injectLogger('TokenInterceptor');
  const token = store.selectSnapshot(AuthState.accessToken);

  request = addToken(request, token);
  logger.debug(`HttpRequest ~ handleRequestAndLoginOn401 ~ ${request.url.split('?')[0]}`, request);

  return next(request).pipe(
    catchError((err) => {
      logger.error(
        `HttpError ~ handleRequestAndLoginOn401 ~ ${request.url.split('?')[0]}`,
        request,
        err,
      );

      return isUnauthorized(err)
        ? store.dispatch(ClearAuth).pipe(
            switchMap(() => from(router.navigate([AppRoute.Login]))),
            switchMap(() => EMPTY),
          )
        : throwError(() => err);
    }),
  );
};

function addToken(request: HttpRequest<unknown>, token?: string) {
  return request.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`,
    },
  });
}

function isUnauthorized(err: unknown) {
  return err instanceof HttpErrorResponse && err.status === HttpStatusCode.Unauthorized;
}

import { Component, signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { ModusButtonModule } from '@trimble-gcs/modus';
import { filter, map } from 'rxjs';
import { AppRoute } from '../../app-route';
import { ConnectService } from '../../connect/connect.service';
import { AuthState } from '../auth.state';

enum View {
  Extension3D,
  ExtensionBrowser,
  ExtensionBrowserConfig,
}

@UntilDestroy()
@Component({
  standalone: true,
  imports: [ModusButtonModule],
  templateUrl: './connect-permission-denied.component.html',
})
export class ConnectPermissionDeniedComponent {
  currentView = signal<View>(View.ExtensionBrowser);
  view = View;

  constructor(
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private connectService: ConnectService,
  ) {
    this.setViewOnNavigationEnd();
    this.subscribeToLoggedIn();
  }

  async viewExtensions() {
    /**
     * HACK
     * Connect does not go to the project extensions screen when you are in the
     * extensions config. To work around this connect bug, we first goto the
     * project details and then to the config screen.
     */
    if (this.currentView() === View.ExtensionBrowserConfig) {
      await this.connectService.goToProjectSettings();
    }

    await this.connectService.goToProjectExtensions();
  }

  private getView(path: string) {
    if (path.includes(AppRoute.Connect3d)) return View.Extension3D;
    if (path.includes(AppRoute.Config)) return View.ExtensionBrowserConfig;

    return View.ExtensionBrowser;
  }

  private setViewOnNavigationEnd() {
    this.router.events
      .pipe(
        filter((data) => data instanceof NavigationEnd),
        map((event) => this.getView(event.url)),
        untilDestroyed(this),
      )
      .subscribe((view) => this.currentView.set(view));
  }

  private subscribeToLoggedIn() {
    this.store
      .select(AuthState.loggedIn)
      .pipe(untilDestroyed(this))
      .subscribe((loggedIn) => {
        const returnPath = this.activatedRoute.snapshot.queryParamMap.get('returnPath') ?? '/';

        if (loggedIn) {
          this.router.navigate([returnPath]);
        } else {
          const view = this.getView(returnPath);
          this.currentView.set(view);
        }
      });
  }
}

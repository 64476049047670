import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
} from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { ErrorState } from '../error-handling/error.state';
import { SetInfoView } from '../options-panel/options-panel.actions';
import { OptionsPanelInfoView } from '../options-panel/options-panel.models';
import { SortInfo } from '../scandata/scandata-query.models';
import { sortScandata } from '../scandata/scandata-sort';
import { SelectOnly, SetSelected, SetSortInfo } from '../scandata/scandata.actions';
import { ScandataModel } from '../scandata/scandata.models';
import { ScandataState } from '../scandata/scandata.state';
import {
  ScandataEmptyComponent,
  ScandataEmptyReason,
} from './scandata-empty/scandata-empty.component';
import { ScandataTableComponent } from './scandata-table/scandata-table.component';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    ScandataTableComponent,
    ScandataEmptyComponent,
    MatProgressBarModule,
    ScrollingModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './scandata-list.component.html',
  styleUrls: ['./scandata-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScandataListComponent {
  private store = inject(Store);

  isLoading = this.store.selectSignal(ScandataState.isLoading);
  scanLoadError = this.store.selectSignal(ErrorState.hasError('scanLoadError'));
  sortInfo = this.store.selectSignal(ScandataState.sortInfo);

  scandataList = computed(() => {
    const data = this.textFilteredScandata();
    const sort = this.sortInfo();
    return sortScandata(data, sort.sortBy, sort.sortDirection);
  });

  private textFilteredScandata = this.store.selectSignal(ScandataState.textFilteredScandata);
  private textFilter = this.store.selectSignal(ScandataState.textFilter);
  private scandata = this.store.selectSignal(ScandataState.scandata);
  private filterCount = this.store.selectSignal(ScandataState.filterCount);

  showScandataEmpty = computed(() => {
    const isLoading = this.isLoading();
    const scanLoadError = this.scanLoadError();
    const scandataList = this.scandataList();
    return isLoading || scanLoadError ? false : scandataList.length === 0;
  });

  scandataEmptyReason = computed(() => {
    const filterCount = this.filterCount();
    const scandata = this.scandata();
    const textFilter = this.textFilter();

    if (filterCount === 0 && scandata.length === 0) {
      return ScandataEmptyReason.NoUploads;
    }

    if (filterCount > 0 || isDefined(textFilter)) {
      return ScandataEmptyReason.NoFilterResults;
    }

    return ScandataEmptyReason.NoUploads;
  });

  selectionChange(scans: ScandataModel[]) {
    this.store.dispatch(new SetSelected(scans));
  }

  chipClick(model: ScandataModel) {
    this.store.dispatch([
      new SetInfoView(OptionsPanelInfoView.Tagging),
      new SelectOnly([model.id]),
    ]);
  }

  sortInfoChange(sortInfo: SortInfo) {
    this.store.dispatch(new SetSortInfo(sortInfo));
  }
}

export enum OptionsPanelView {
  None = 'None',
  DownloadStatus = 'DownloadStatus',
  ListFilters = 'ListFilters',
  SelectedDetails = 'SelectedDetails',
  ClassificationEdit = 'ClassificationEdit',
}

export enum OptionsPanelInfoView {
  Properties = 'Properties',
  Tagging = 'Tagging',
  Classification = 'Classification',
}

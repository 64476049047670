import { isDefined } from '@trimble-gcs/common';
import { isString } from '@trimble-gcs/modus';
import { ConnectFile } from 'trimble-connect-workspace-api';

export class ExternalFileId {
  fileId: string | null = null;
  versionId: string | null = null;
  revision: string | null = null;

  get revisionLabel(): string | null {
    return isDefined(this.revision) ? `v.${this.revision}` : null;
  }

  get id(): string | null {
    return this.fileId && this.versionId && this.revision
      ? `${this.fileId}:${this.versionId}:${this.revision}`
      : null;
  }

  constructor(idOrFile?: string | ConnectFile) {
    if (isString(idOrFile)) {
      if (idOrFile.length === 0) return;

      const parts = idOrFile.split(':');
      this.fileId = parts[0];
      this.versionId = parts.length > 1 ? parts[1] : null;
      this.revision = parts.length > 2 ? parts[2] : null;
    } else if (isDefined(idOrFile)) {
      const file = idOrFile as ConnectFile;
      this.fileId = file.id;
      this.versionId = file.versionId ?? null;
      this.revision = file.revision?.toString() ?? null;
    }
  }
}

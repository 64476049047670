import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { isNil } from '@trimble-gcs/common';
import {
  AddToImportScans,
  ClearImportScans,
  PatchImportFile,
  PatchImportScan,
} from './import.actions';
import { ImportFileStatus, ImportScan, ImportScanStatus, ImportStatus } from './import.models';

export interface ImportStateModel {
  importScans: ImportScan[];
}

@State<ImportStateModel>({
  name: 'importState',
  defaults: { importScans: [] },
})
@Injectable()
export class ImportState {
  @Selector() static importScans(state: ImportStateModel): ImportScan[] {
    return state.importScans;
  }

  @Selector() static status(state: ImportStateModel): ImportStatus {
    const importScans = state.importScans;
    if (importScans.length === 0) return ImportStatus.NotStarted;

    const busy = importScans.some(
      (scan) =>
        scan.status !== ImportScanStatus.Error && scan.status !== ImportScanStatus.Completed,
    );
    if (busy) return ImportStatus.Busy;

    const error = importScans.some((scan) => scan.status === ImportScanStatus.Error);
    if (error) return ImportStatus.Error;

    return ImportStatus.Completed;
  }

  @Selector() static activeImportFileCount(state: ImportStateModel): number {
    const activeImports = state.importScans.reduce((prev, cur) => {
      if (cur.status === ImportScanStatus.Completed || cur.status === ImportScanStatus.Error) {
        return prev;
      }

      const fileCount = cur.files.filter(
        (file) => file.status === ImportFileStatus.Pending || file.status === ImportFileStatus.Busy,
      ).length;

      return fileCount > 0 ? prev + fileCount : prev + 1;
    }, 0);

    return activeImports;
  }

  @Action(AddToImportScans)
  addToImportScans(ctx: StateContext<ImportStateModel>, { importScans }: AddToImportScans) {
    const combined = [...ctx.getState().importScans, ...importScans];
    ctx.patchState({ importScans: combined });
  }

  @Action(PatchImportScan)
  patchImportScan(ctx: StateContext<ImportStateModel>, { importScan }: PatchImportScan) {
    const importScans = ctx.getState().importScans.map((item) => {
      return item.id === importScan.id ? importScan : item;
    });
    ctx.patchState({ importScans });
  }

  @Action(PatchImportFile)
  patchImportFile(ctx: StateContext<ImportStateModel>, { importFile }: PatchImportFile) {
    const importScans = ctx.getState().importScans.map((scan) => {
      const foundFile = scan.files.some((file) => file.id === importFile.id);
      if (isNil(foundFile)) return scan;

      return {
        ...scan,
        files: scan.files.map((file) => (file.id === importFile.id ? importFile : file)),
      };
    });

    ctx.patchState({ importScans });
  }

  @Action(ClearImportScans)
  clearImportScans(ctx: StateContext<ImportStateModel>) {
    ctx.setState({ importScans: [] });
  }
}

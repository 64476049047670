export enum AppRoute {
  Login = 'login',
  Logout = 'logout',
  MapView = 'map-view',
  ListView = 'list-view',
  Config = 'config',
  Connect3d = 'connect-3d',
  ConnectPermissionDenied = 'connect-permission-denied',
  NoProjectAccess = 'no-project-access',
  LicenseCheck = 'license-check',
}

// Same limit is used by Clarity UI for download (50GB)
export const CLARITY_IMPORT_MAX_FILE_SIZE_BYTES = 50 * 1024 ** 3;

export enum ClarityTreeItemType {
  previous = 'previous',
  folder = 'folder',
  file = 'file',
}

export interface ClarityUser {
  id: number;
}

export interface ClarityWorld {
  id: number;
  ownerId: number;
  name: string;
  description: string;
  entitlement: {
    entitlementId: string;
    accountName: string;
  };
}

export interface ClarityUserWorld extends ClarityWorld {
  role: ClarityRole;
}

export interface ClarityWorldMember {
  id: number;
  name: string;
  email: string;
  worldId: number;
  role: ClarityRole;
  active: true;
}

export interface ClarityRole {
  id: number;
  name: string;
  description: string;
  active: true;
  type: string;
  permissions: string[];
  system: boolean;
}

export interface ClarityTag {
  id: string;
  name: string;
  type: string;
  parentId?: string | null;
  fileIds?: string[];
  isDeleted?: boolean;
}

export interface ClarityFile {
  id: string;
  name: string;
  type: string;
  size: number;
  status?: string;
  isDeleted?: boolean;
}

export interface ClarityFileDownload {
  fileName: string;
  size: number;
  downloadUrl: string;
}

export interface ClarityTreeItem {
  id: string;
  worldId: number;
  parentId?: string;
  name: string;
  size: number;
  type: ClarityTreeItemType;
  selected: boolean;
}

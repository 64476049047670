import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { from, switchMap } from 'rxjs';
import manifest from '../../../manifest.json';
import { AppRoute } from '../../app-route';
import { AppState } from '../../app-state/app.state';
import { AuthState } from '../../auth/auth.state';
import { Connect3dPanelService } from '../../connect/connect-3d-panel.service';
import { ConnectExplorerService } from '../../connect/connect-explorer.service';
import { ConnectPanelService } from '../../connect/connect-panel.service';
import { ConnectCommand } from '../../connect/connect.models';
import { ConnectService } from '../../connect/connect.service';
import { ClearError } from '../../error-handling/error.actions';
import { noopErrorObserver } from '../../logging/noop-error-observer';
import { FeatureLayerService } from '../../map/feature-layer/feature-layer.service';
import { ScandataService } from '../../scandata/scandata.service';
import { ScandataState } from '../../scandata/scandata.state';
import { createGeospatialMenu } from './ext-host.menu';

@UntilDestroy()
@Component({
  selector: 'sd-ext-host',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './ext-host.component.html',
  styleUrls: ['./ext-host.component.scss'],
})
export class ExtHostComponent implements OnInit {
  constructor(
    private router: Router,
    private store: Store,
    private connectService: ConnectService,
    private connectPanelService: ConnectPanelService,
    private connect3dPanelService: Connect3dPanelService,
    private connectExplorerService: ConnectExplorerService,
    private scandataService: ScandataService,
    private featureLayerService: FeatureLayerService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.setExtensionMenu();
    this.subscribeToNavigationCommands();
    this.connectPanelService.subscribeToConnectEvents();
    this.connect3dPanelService.subscribeToConnectEvents();
    this.connectExplorerService.subscribeForClosingPrompt();
  }

  private async setExtensionMenu() {
    // LM: Connect Workspace API is the same for file view and 3d view.
    // setMenu throws an error if called in Connect 3d view
    try {
      const workspace = await this.connectService.getWorkspace();
      await workspace.api.ui.setMenu(
        createGeospatialMenu(this.store.selectSnapshot(AppState.settings)),
      );
    } catch {}
  }

  private subscribeToNavigationCommands() {
    from(this.connectService.getWorkspace())
      .pipe(
        switchMap((workspace) => workspace.command$),
        untilDestroyed(this),
      )
      .subscribe({
        next: (event) => {
          switch (event.data) {
            case manifest.configCommand:
              this.router.navigate([AppRoute.Config]);
              break;

            case ConnectCommand.ScandataBrowser:
              this.router.navigate([''], { onSameUrlNavigation: 'reload' });
              this.reloadScansAndFeatures();
              break;

            default:
              break;
          }
        },
      });
  }

  private reloadScansAndFeatures() {
    if (!this.store.selectSnapshot(AuthState.loggedIn)) return;
    if (this.store.selectSnapshot(ScandataState.isLoading)) return;

    this.store
      .dispatch(new ClearError('scanLoadError'))
      .pipe(
        switchMap(() => this.scandataService.refreshScandata()),
        switchMap(() => this.featureLayerService.loadFeatures()),
      )
      .subscribe(noopErrorObserver);
  }
}

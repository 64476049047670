import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import {
  ModusButtonModule,
  ModusFormFieldModule,
  ModusIconModule,
  ModusInputModule,
  ModusSwitchModule,
} from '@trimble-gcs/modus';
import { ColorPickerModule } from 'ngx-color-picker';
import { map, startWith, switchMap } from 'rxjs';
import { isNil } from '../../../../../../libs/common/src';
import { ClassificationScheme } from '../../classification/classification-scheme.model';
import { ClassificationSchemeState } from '../../classification/classification-scheme.state';
import { ClassificationService } from '../../classification/classification.service';
import { ErrorState } from '../../error-handling/error.state';
import { LabelValueComponent } from '../../label-value/label-value.component';
import { colorHexStripAlpha } from '../../utils/color-converter';
import { NotWhitespaceStringValidator } from '../../utils/not-whitespace-string-validator';
import { SetView } from '../options-panel.actions';
import { OptionsPanelView } from '../options-panel.models';

@UntilDestroy()
@Component({
  selector: 'sd-classification-edit',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LabelValueComponent,
    ColorPickerModule,
    ModusFormFieldModule,
    ModusInputModule,
    ModusSwitchModule,
    ModusButtonModule,
    ModusIconModule,
    MatProgressBarModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './classification-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClassificationEditComponent {
  classificationScheme = this.store.selectSignal(ClassificationSchemeState.classificationForEdit);
  classificationSaveError = this.store.selectSignal(ErrorState.hasError('classificationSaveError'));

  formGroup = this.createFormGroup();

  busySaving = signal(false);
  visibleLabel = this.getVisibleLabel();
  saveDisabled = this.getSaveDisabled();

  stripAlpha = colorHexStripAlpha;

  constructor(
    private store: Store,
    private classificationService: ClassificationService,
  ) {
    this.createClassificationSchemeEffect();
  }

  private createFormGroup() {
    return new FormGroup({
      name: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, NotWhitespaceStringValidator],
      }),
      rgba: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      visible: new FormControl<boolean>(true, {
        nonNullable: true,
      }),
    });
  }

  private getVisibleLabel() {
    return toSignal(
      this.formGroup.controls.visible.valueChanges.pipe(
        startWith(this.formGroup.controls.visible.value),
        map((value) => (value ? 'Visible' : 'Hidden')),
      ),
      { initialValue: 'Visible' },
    );
  }

  private getSaveDisabled() {
    const pristineOrInvalid = toSignal(
      this.formGroup.valueChanges.pipe(
        startWith(this.formGroup.value),
        map(() => this.formGroup.pristine || this.formGroup.invalid),
      ),
      { initialValue: true },
    );

    return computed(() => {
      return this.busySaving() || pristineOrInvalid();
    });
  }

  private createClassificationSchemeEffect() {
    return effect(() => {
      const classificationScheme = this.classificationScheme();
      if (isNil(classificationScheme)) return;

      this.formGroup.setValue({
        name: classificationScheme.name,
        rgba: classificationScheme.rgba,
        visible: classificationScheme.visible,
      });
    });
  }

  close() {
    this.store.dispatch(new SetView(OptionsPanelView.None));
  }

  setColor(color: string) {
    const rgbaNew = color.slice(1).toUpperCase();
    const rgbaCurrent = this.formGroup.value.rgba;

    const valueChanged = rgbaNew !== rgbaCurrent;
    if (!valueChanged) return;

    this.formGroup.markAsDirty();
    this.formGroup.controls.rgba.setValue(rgbaNew);
  }

  trimInputValue(formControl: FormControl<string>) {
    formControl.setValue(formControl.value.trim());
  }

  save() {
    this.setFormSaving(true);

    const classificationScheme = {
      ...this.classificationScheme()!,
      ...this.formGroup.value,
    } satisfies ClassificationScheme;

    this.getClassificationSchemesForSave(classificationScheme)
      .pipe(
        switchMap((classificationSchemes) => {
          return this.classificationService.saveClassificationSchemes(classificationSchemes);
        }),
      )
      .subscribe({
        next: () => this.close(),
        error: () => this.setFormSaving(false),
      });
  }

  private setFormSaving(saving: boolean) {
    if (saving) this.formGroup.disable();
    else this.formGroup.enable();

    this.busySaving.set(saving);
  }

  private getClassificationSchemesForSave(updatedClassificationScheme: ClassificationScheme) {
    return this.classificationService.getClassificationSchemes().pipe(
      map((classificationSchemes) => {
        const schemes = classificationSchemes.filter(
          (item) => item.id !== updatedClassificationScheme.id,
        );
        schemes.push(updatedClassificationScheme);

        return schemes;
      }),
    );
  }
}

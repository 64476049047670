import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { buildUrl } from '@trimble-gcs/common';
import { catchError, combineLatest, EMPTY, filter, map, retry, switchMap, take } from 'rxjs';
import { AppRoute } from '../app-route';
import { SetProjectRegion } from '../app-state/app.actions';
import { AppState } from '../app-state/app.state';
import { AuthState } from '../auth/auth.state';
import { injectLogger } from '../logging/logger-injection';
import { TenancyService } from '../tenancy/tenancy.service';
import { ProjectRegion } from './project-region.models';

interface ProjectRegionDto {
  sdsRegionCode: string;
  sdsBaseUrl: string;
  featuresUrl: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectRegionService {
  private logger = injectLogger('ProjectRegionService');

  constructor(
    private store: Store,
    private http: HttpClient,
    private router: Router,
    private tenancyService: TenancyService,
  ) {}

  loadProjectRegion() {
    const projectsUrl = this.store.selectSnapshot(AppState.settings).endpoints.projects.url;

    return combineLatest([
      this.store.select(AppState.project),
      this.store.select(AuthState.loggedIn),
    ]).pipe(
      filter(([connectProject, loggedIn]) => !!connectProject && loggedIn),
      take(1),
      switchMap(([connectProject]) => {
        const url = buildUrl(projectsUrl, `/${connectProject.id}`);
        return this.http.get<ProjectRegionDto>(url);
      }),
      map((projectRegionDto) => {
        const projectRegion: ProjectRegion = {
          scandata: {
            url: projectRegionDto.sdsBaseUrl,
            protected: true,
            addClientHeaders: true,
          },
          features: {
            url: projectRegionDto.featuresUrl,
            protected: true,
          },
        };

        return projectRegion;
      }),
      switchMap((projectRegion) => {
        return this.store.dispatch(new SetProjectRegion(projectRegion));
      }),
      retry({
        count: 1,
        delay: (error: HttpErrorResponse) => this.shouldRetry(error),
      }),
      catchError((err: HttpErrorResponse) => {
        // This should only happen when in standalone mode.
        // When running as a connect extension, connect will block the
        // the user from accessing the project if the user is not
        // a member of the project team.
        if (err.status === HttpStatusCode.Forbidden) {
          this.router.navigate([AppRoute.NoProjectAccess]);
        }

        this.logger.error(err.message, {}, err);

        return EMPTY;
      }),
    );
  }

  private shouldRetry(err: HttpErrorResponse) {
    if (err.status === HttpStatusCode.NotFound) {
      return this.tenancyService.createTenancy();
    }

    throw err;
  }
}

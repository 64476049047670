import { LogsInitConfiguration } from '@datadog/browser-logs';
import { FeatureFlagKey } from '../feature-flags/feature-flag-key';
import { LogLevel, LoggerType } from '../logging/logger-types';

export enum AppContext {
  ConnectExtension = 'ConnectExtension',
  ConnectIntegrated = 'ConnectIntegrated',
  Standalone = 'Standalone',
  Unknown = 'Unknown',
}

export interface Endpoint {
  url: string;
  protected: boolean;
  addClientHeaders?: boolean;
}

export type Endpoints = {
  [key in 'connect' | 'projects' | 'regions' | 'maps' | 'clarity']: Endpoint;
};

export interface AppSettings {
  title: string;
  production: boolean;
  appContext: AppContext.ConnectIntegrated | AppContext.Standalone;
  featureFlags: Record<FeatureFlagKey, boolean>;
  endpoints: Endpoints;
  baseUrl: string;
  tidSettings: TidSettings;
  logging: LoggerSettings;
  gainsight: GainsightSettings;
}

export interface TidSettings {
  app_name: string;
  client_id: string;
  redirect_uri: string;
  post_logout_redirect_uri: string;
  issuer: string;
  authorization_endpoint: string;
  token_endpoint: string;
  userinfo_endpoint: string;
  revocation_endpoint: string;
  end_session_endpoint: string;
  tenantDomain: string;
}

export interface LoggerSettings {
  dataDog: LogsInitConfiguration;
  loggers: { [key in LoggerType]: { logLevel: LogLevel } };
}

export interface GainsightSettings {
  tag: string;
}

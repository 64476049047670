<div class="flex flex-col h-full">
  <div class="flex w-full relative">
    @if (isLoading()) {
      <div class="w-full absolute">
        <mat-progress-bar class="mds-x-small" [mode]="'indeterminate'"></mat-progress-bar>
      </div>
    }
  </div>

  <div class="flex flex-row p-4 pr-6 border-b border-gray-1">
    <div class="h-8 flex flex-1 flex-wrap content-center font-semibold">Filters</div>
    <button
      id="options-panel-filters-close"
      class="text-gray-8"
      modus-icon-button
      modus-tooltip="Close"
      (click)="close()"
    >
      <md-icon>close</md-icon>
    </button>
  </div>

  <div class="flex-1 overflow-y-scroll p-4" cdkScrollable [formGroup]="formGroup">
    <!-- name -->
    <modus-form-field>
      <modus-label>Name</modus-label>
      <input
        modus-input
        [formControl]="formGroup.controls.name"
        data-cy="name-input"
        (keydown.enter)="applyFilters()"
      />
    </modus-form-field>

    <!-- status -->
    <label class="block text-small text-gray-8 py-1">Status</label>
    <mat-form-field class="w-full mb-2" subscriptSizing="dynamic">
      <mat-select
        class="modus-multi-select"
        panelClass="modus-multi-select-panel"
        [formControl]="formGroup.controls.status"
        multiple
        disableRipple
        data-cy="status-select"
      >
        <mat-select-trigger>
          {{ selectedStatusLabel() }}
        </mat-select-trigger>
        @for (pointcloudStatus of pointcloudStatusList(); track pointcloudStatus.key) {
          <mat-option [value]="pointcloudStatus.key">
            {{ pointcloudStatus.displayValue }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <!-- capture date -->
    <modus-date-picker label="Capture Date" class="block mb-2" data-cy="capture-date-picker">
      <modus-date-input
        type="start"
        placeholder="From"
        format="yyyy-mm-dd"
        allowed-chars-regex="[\d-]"
        show-calendar-icon="true"
        [max]="maxCaptureDate()"
        [formControl]="formGroup.controls.captureFromDate"
        data-cy="capture-from-date-input"
      ></modus-date-input>
      <modus-date-input
        type="end"
        placeholder="To"
        format="yyyy-mm-dd"
        allowed-chars-regex="[\d-]"
        show-calendar-icon="true"
        [max]="maxCaptureDate()"
        [formControl]="formGroup.controls.captureToDate"
        data-cy="capture-to-date-input"
      ></modus-date-input>
    </modus-date-picker>

    <!-- scanner type -->
    <modus-autocomplete-input
      #scannerTypeAutocomplete
      [label]="'Scanner Type'"
      placeholder="Begin typing to search..."
      [clearable]="true"
      [options]="scannerTypeOptionList"
      [formControl]="formGroup.controls.scannerType"
      data-cy="scanner-type-autocomplete"
    >
    </modus-autocomplete-input>
    <modus-option-list #scannerTypeOptionList="modusOptionList">
      @for (scannerType of filteredScannerTypes(); track scannerType) {
        <modus-option [value]="scannerType">{{ scannerType }}</modus-option>
      }
    </modus-option-list>

    <!-- tags chipset -->
    <label class="block text-small text-gray-8 pt-1">Tags</label>
    <div class="flex justify-start flex-wrap">
      @for (tag of formGroup.controls.tags.value; track tag) {
        <modus-chip
          class="m-1"
          show-close
          [value]="tag"
          data-cy="tag-chip"
          (closeClick)="tagRemoved(tag)"
        >
        </modus-chip>
      }
    </div>

    <!-- tags autocomplete -->
    <!-- NB! Don't use (blur).
      Older code used the blur event to reset the FormControl when the control lost focus
      wihtout an autocomplete option being selected. modus-autocomplete does not fire the
      (optionSelected) event if (blur) has been handled. To stay true to the Modus paradigm,
      the user has to click the 'Clear' button to clear the input, otherwise we just leave it there.
      -->
    <modus-autocomplete-input
      #tagSelectorAutocomplete
      placeholder="Begin typing to search..."
      [clearable]="true"
      [options]="tagOptionList"
      [formControl]="formGroup.controls.tagSelector"
      data-cy="tag-autocomplete"
      (optionSelected)="tagSelected($event)"
    >
    </modus-autocomplete-input>
    <modus-option-list #tagOptionList="modusOptionList">
      @for (tag of filteredTags(); track tag) {
        <modus-option [value]="tag">{{ tag }}</modus-option>
      }
    </modus-option-list>

    <div class="flex flex-col mb-1">
      <div class="flex items-center text-small text-gray-8">
        <md-icon class="!text-base text-blue-light mr-1">info_outlined</md-icon>
        Search tags individually or a combination of
      </div>
      <mat-radio-group [formControl]="formGroup.controls.tagsMatchAll">
        <mat-radio-button color="primary" [value]="true" data-cy="and-radio">and</mat-radio-button>
        <mat-radio-button color="primary" class="ml-4" [value]="false" data-cy="or-radio"
          >or</mat-radio-button
        >
      </mat-radio-group>
    </div>

    <!-- uploaded by -->
    <label class="block text-small text-gray-8 py-1">Uploaded By</label>
    <mat-form-field class="w-full mb-2" subscriptSizing="dynamic">
      <mat-select
        class="modus-multi-select"
        panelClass="modus-multi-select-panel"
        [formControl]="formGroup.controls.uploadedBy"
        multiple
        disableRipple
        data-cy="uploaded-by-select"
      >
        <mat-select-trigger>
          {{ selectedUploadedByLabel() }}
        </mat-select-trigger>
        @for (user of users(); track user.id) {
          <mat-option [value]="user.id">
            {{ user.fullName }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <!-- classified -->
    <div class="pt-2">
      <modus-switch
        label="Classified"
        [formControl]="formGroup.controls.isClassified"
        data-cy="classified-switch"
      ></modus-switch>
    </div>

    <!-- station -->
    <div class="pt-2">
      <modus-switch
        label="Contains Station(s)"
        [formControl]="formGroup.controls.containsStations"
        data-cy="stations-switch"
      ></modus-switch>
    </div>
  </div>

  <!-- button group -->
  <div class="flex p-4 justify-end border-t border-gray-1">
    <button
      id="options-panel-filters-clear"
      modus-button
      (click)="clearFilters()"
      [disabled]="isClearDisabled()"
      class="mr-4"
      color="secondary"
    >
      Clear Filters
    </button>

    <button
      id="options-panel-filters-apply"
      modus-flat-button
      (click)="applyFilters()"
      [disabled]="isApplyDisabled()"
    >
      Apply Filters
    </button>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, input, output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { isNil } from '@trimble-gcs/common';
import { ModusButtonModule, ModusIconModule } from '@trimble-gcs/modus';
import { BaseLayer } from '../base-layer/base-layer.models';
import { MapTool } from '../map.models';

@Component({
  selector: 'sd-map-toolbar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ModusButtonModule, ModusIconModule, MatSelectModule],
  templateUrl: './map-toolbar.component.html',
})
export class MapToolbarComponent {
  baseLayers = input.required<BaseLayer[], BaseLayer[] | null>({
    transform: (value) => value ?? [],
  });

  activeBaseLayer = input.required<BaseLayer | null>();
  activeMapTool = input.required<MapTool>();

  mapToolSelected = output<MapTool>();
  zoomInClick = output();
  zoomOutClick = output();
  fitToViewClick = output();
  baseLayerChange = output<BaseLayer>();

  layerOptionControl = new FormControl<string | null>(null);

  panSelectActive = computed(() => this.activeMapTool() === MapTool.Default);
  rectangleSelectActive = computed(() => this.activeMapTool() === MapTool.RectangleSelect);
  polygonSelectActive = computed(() => this.activeMapTool() === MapTool.PolygonSelect);

  private layerOptionControlValue = toSignal(this.layerOptionControl.valueChanges, {
    initialValue: null,
  });

  constructor() {
    effect(() => {
      const activeBaseLayer = this.activeBaseLayer();
      if (isNil(activeBaseLayer)) return;

      this.layerOptionControl.setValue(activeBaseLayer.name, { emitEvent: false });
    });

    effect(() => {
      const selectedLayerName = this.layerOptionControlValue();
      const selectedLayer = this.baseLayers().find((layer) => layer.name === selectedLayerName);
      if (isNil(selectedLayer)) return;

      this.baseLayerChange.emit(selectedLayer);
    });
  }

  panSelectClick() {
    this.mapToolSelected.emit(MapTool.Default);
  }

  rectangleSelectClick() {
    const mapTool = this.rectangleSelectActive() ? MapTool.Default : MapTool.RectangleSelect;
    this.mapToolSelected.emit(mapTool);
  }

  polygonSelectClick() {
    const mapTool = this.polygonSelectActive() ? MapTool.Default : MapTool.PolygonSelect;
    this.mapToolSelected.emit(mapTool);
  }
}

import { Inject, Injectable } from '@angular/core';
import { ILogger, LOGGER_SERVICE_LOGGERS, LOGGER_SERVICE_NAME } from './logger-types';

/**
 * LoggerService implements ILogger and provides a root-level entry point to logging.
 * It holds an array of concrete logger implementations to which the actual work is delegated.
 *
 * An instance can be obtained from Angular dependency injection, but it also has a factory method
 * which can be called to create named instances of the LoggerService and its concrete loggers.
 *
 * It is important to note that the factory method can only be called within
 * an Angular injection context; for more about injection contexts see
 * https://angular.dev/guide/di/dependency-injection-context#
 */
@Injectable({
  providedIn: 'root',
})
export class LoggerService implements ILogger {
  constructor(
    @Inject(LOGGER_SERVICE_NAME) public readonly name: string,
    @Inject(LOGGER_SERVICE_LOGGERS) public readonly loggers: ILogger[],
  ) {}

  /**
   * Factory method to create a named instance of LoggerService and its ILogger instances.
   * @param name The name of the created LoggerService.
   * @returns An instance of LoggerService.
   */
  createNamedLogger(name: string): ILogger {
    const loggers = this.loggers.map((logger) => logger.createNamedLogger(name));
    return new LoggerService(name, loggers);
  }

  debug(message: string, context?: object, error?: Error): void {
    this.loggers.forEach((logger) => logger.debug(message, context, error));
  }

  info(message: string, context?: object, error?: Error): void {
    this.loggers.forEach((logger) => logger.info(message, context, error));
  }

  warn(message: string, context?: object, error?: Error): void {
    this.loggers.forEach((logger) => logger.warn(message, context, error));
  }

  error(message: string, context?: object, error?: Error): void {
    this.loggers.forEach((logger) => logger.error(message, context, error));
  }
}

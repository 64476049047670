import { FeatureFlagKey } from '../../feature-flags/feature-flag-key';

export enum PickerType {
  Upload = 'Upload',
  Clarity = 'Clarity',
  // connect, google drive, one drive, dropbox
}

export const FILE_PICKERS_CONFIG: FilePickerConfig[] = [
  { pickerType: PickerType.Upload, featureFlagKey: 'tools_import.upload', icon: '', tooltip: '' },
  {
    pickerType: PickerType.Clarity,
    featureFlagKey: 'tools_import.clarity',
    icon: 'clarity',
    tooltip: 'Import file/s from Clarity',
  },
];

export interface FilePickerConfig {
  pickerType: PickerType;
  featureFlagKey: FeatureFlagKey;
  icon: string;
  tooltip: string;
}

export interface FilePicker extends FilePickerConfig {
  enabled: boolean;
}

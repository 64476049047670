import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { ClassificationComponent } from './classification/classification.component';
import { ConfigTab } from './config-tab.ts';
import { SetActiveConfigTab } from './config.actions';
import { ConfigState } from './config.state';
import { StorageComponent } from './storage/storage.component';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [CommonModule, MatTabsModule, StorageComponent, ClassificationComponent],
  templateUrl: './config.component.html',
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 100%;
        max-height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigComponent {
  private tabs = {
    [ConfigTab.Storage]: 0,
    [ConfigTab.Classification]: 1,
  };

  activeTab = toSignal(this.store.select(ConfigState.activeTab), { requireSync: true });
  selectedTabIndex = computed(() => this.tabs[this.activeTab()]);

  queryParams = toSignal(this.route.queryParams, { requireSync: true });
  showBackNavigation = computed(() => !!this.queryParams()['showBackNavigation']);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
  ) {}

  viewScandata() {
    this.router.navigate(['/']);
  }

  tabChange(event: MatTabChangeEvent) {
    const tab = Object.keys(this.tabs)[event.index] as ConfigTab;
    this.store.dispatch(new SetActiveConfigTab(tab));
  }
}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModusIconModule } from '@trimble-gcs/modus';
import { FileSizePipe } from '@trimble-gcs/ngx-common';
import { ClarityTreeItem, ClarityTreeItemType } from '../clarity.models';

@UntilDestroy()
@Component({
  selector: 'sd-clarity-file-browser-table',
  standalone: true,
  imports: [CommonModule, ModusIconModule, MatTableModule, FileSizePipe],
  templateUrl: './clarity-file-browser-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClarityFileBrowserTableComponent {
  treeItems = input.required<ClarityTreeItem[]>();
  showPrevious = input(false);
  disabled = input<boolean>(false);

  onPreviousClick = output();
  onFolderClick = output<ClarityTreeItem>();
  onFileClick = output<ClarityTreeItem>();

  dataSource = computed(() => {
    const sortedTreeItems = this.treeItems().sort(
      (a, b) => b.type.localeCompare(a.type) || a.name.localeCompare(b.name),
    );
    const data = this.showPrevious() ? [this.backTreeItem, ...sortedTreeItems] : sortedTreeItems;

    return new MatTableDataSource<ClarityTreeItem>(data);
  });

  readonly treeItemType = ClarityTreeItemType;
  readonly displayedColumns = ['thumbnailUrl', 'name', 'size'];

  private readonly backTreeItem: ClarityTreeItem = {
    id: '',
    type: ClarityTreeItemType.previous,
    name: '...',
    size: 0,
    selected: false,
    worldId: 0,
  };

  getItemId(index: number, item: ClarityTreeItem) {
    return item.id;
  }

  itemClick(item: ClarityTreeItem) {
    if (this.disabled()) return;

    if (item.type === ClarityTreeItemType.previous) {
      this.onPreviousClick.emit();
    } else if (item.type === ClarityTreeItemType.folder) {
      this.onFolderClick.emit(item);
    } else {
      this.onFileClick.emit(item);
    }
  }
}

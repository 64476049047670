import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { buildUrl, isDefined, isNil } from '@trimble-gcs/common';
import {
  catchError,
  combineLatest,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
  take,
} from 'rxjs';
import { AppState } from '../../../app-state/app.state';
import { ALLOWED_EXTENSIONS, ImportFile } from '../../import.models';
import { PickerType } from '../file-picker.models';
import {
  CLARITY_IMPORT_MAX_FILE_SIZE_BYTES,
  ClarityFile,
  ClarityTag,
  ClarityTreeItem,
  ClarityTreeItemType,
  ClarityUser,
  ClarityUserWorld,
  ClarityWorld,
  ClarityWorldMember,
} from './clarity.models';

@Injectable({
  providedIn: 'root',
})
export class ClarityService {
  private readonly http = inject(HttpClient);
  private readonly store = inject(Store);

  getWorlds() {
    /**
     * Clarity does not have single endpoint that returns all the user worlds with permissions.
     * We have to concatenate requests here to fetch the the user worlds and then get the
     * user's roles/permissions for each world.
     */
    const me$ = this.getClarityUrl(`/users/me`).pipe(
      switchMap((url) => this.http.get<ClarityUser>(url)),
    );
    const worlds$ = this.getClarityUrl('/worlds').pipe(
      switchMap((url) => this.http.get<ClarityWorld[]>(url)),
    );
    return combineLatest([me$, worlds$]).pipe(
      switchMap(([me, worlds]) => {
        const worlds$ = worlds.map((world) => this.getWorldWithUserPermissions(me, world));

        return forkJoin(worlds$).pipe(map((worlds) => worlds.filter(isDefined)));
      }),
    );
  }

  getTreeItems(world: ClarityUserWorld) {
    const tags$ = this.getTags(world.id);
    const files$ = this.getFiles(world.id);
    return combineLatest([tags$, files$]).pipe(
      map(([tags, files]) => this.buildTreeItems(world, tags, files)),
    );
  }

  mapToImportFiles(treeItems: ClarityTreeItem[]): Observable<ImportFile[]> {
    // TODO: Fetch each treeItem's download url from Clarity API, and map treeItem to ImportFile
    // This will be changed in the next PR.
    return of(
      treeItems.map((treeItem) => {
        return {
          id: treeItem.id,
          name: treeItem.name,
          size: treeItem.size,
          pickerType: PickerType.Clarity,
        } satisfies ImportFile;
      }),
    );
  }

  private getClarityUrl(path: string) {
    return this.store.select(AppState.endpoints).pipe(
      map((endpoints) => endpoints.clarity),
      filter(isDefined),
      map((endpoint) => buildUrl(endpoint.url, path)),
      take(1),
    );
  }

  private getWorldWithUserPermissions(
    user: ClarityUser,
    world: ClarityWorld,
  ): Observable<ClarityUserWorld | undefined> {
    return this.getClarityUrl(`/worlds/${world.id}/members`).pipe(
      switchMap((url) => this.http.get<ClarityWorldMember[]>(url)),
      catchError(() => of([])),
      map((worldMembers) => {
        const role = worldMembers.find((wm) => wm.id === user.id)?.role;
        if (role?.active !== true) return;

        return {
          ...world,
          role,
        } satisfies ClarityUserWorld;
      }),
    );
  }

  private getTags(worldId: number) {
    const path = `/worlds/${worldId}/tags`;
    return this.getClarityUrl(path).pipe(switchMap((url) => this.http.get<ClarityTag[]>(url)));
  }

  private getFiles(worldId: number) {
    const path = `/worlds/${worldId}/files`;
    return this.getClarityUrl(path).pipe(switchMap((url) => this.http.get<ClarityFile[]>(url)));
  }

  private buildTreeItems(world: ClarityUserWorld, tagList: ClarityTag[], fileList: ClarityFile[]) {
    // NOTE: Implementation copied from Clarity

    // map files to tree items
    const files = fileList
      .filter((file) => this.allowFileImport(world, file))
      .map((file) => this.mapFileToTreeItem(world, file));

    // map tags to tree items
    const tags = tagList
      .filter((tag) => tag.type === 'tree' && !tag.isDeleted)
      .map((tag) => this.mapTagToTreeItem(world, tag));

    // map tags.fileIds to file.parentId
    const parentMap = new Map();
    tagList.forEach((tag) => {
      if (isNil(tag.fileIds)) return;
      tag.fileIds.map((ft) => parentMap.set(ft, tag.id));
    });

    files.forEach((file) => (file.parentId = parentMap.get(file.id)));

    return tags.concat(files);
  }

  private mapFileToTreeItem(world: ClarityUserWorld, file: ClarityFile): ClarityTreeItem {
    return {
      id: file.id,
      worldId: world.id,
      name: file.name,
      size: file.size,
      type: ClarityTreeItemType.file,
      selected: false,
    };
  }

  private mapTagToTreeItem(world: ClarityUserWorld, tag: ClarityTag): ClarityTreeItem {
    return {
      id: tag.id,
      worldId: world.id,
      name: tag.name,
      size: 0,
      type: ClarityTreeItemType.folder,
      selected: false,
      parentId: tag.parentId ?? undefined,
    };
  }

  private allowFileImport(world: ClarityUserWorld, file: ClarityFile): boolean {
    if (!this.hasWorldPermission(world, 'downloadData')) return false;
    if (!this.allowFileType(file.name)) return false;
    if (file.size > CLARITY_IMPORT_MAX_FILE_SIZE_BYTES) return false;

    return true;
  }

  private hasWorldPermission(world: ClarityUserWorld, permission: string) {
    return world.role?.active && world.role.permissions.includes(permission);
  }

  private allowFileType(fileName: string) {
    const fileExtension = `.${fileName.split('.').at(-1)?.toLowerCase()}`;
    return ALLOWED_EXTENSIONS.includes(fileExtension);
  }
}

import { inject } from '@angular/core';
import { LoggerService } from './logger.service';


const serviceMap = new Map<string, LoggerService>();

export function injectLogger(name: string): LoggerService {
  const current = inject(LoggerService);
  if (!name) return current;
  if (serviceMap.has(name)) return serviceMap.get(name)!;

  const service = current.createNamedLogger(name) as LoggerService;
  serviceMap.set(name, service);
  return service;
}

<div
  class="flex items-center"
  [ngClass]="{
    'hover:!bg-white !cursor-default opacity-50': disabled(),
  }"
>
  @for (breadcrumb of breadcrumbs(); track breadcrumb.id) {
    @if ($last === false) {
      <span
        (click)="breadcrumbClick(breadcrumb)"
        [ngClass]="{
          'hover:text-primary cursor-pointer': disabled() === false,
        }"
        >{{ breadcrumb.name }}</span
      >
      <div class="mx-1 text-sm text-gray-4">></div>
    } @else {
      {{ breadcrumb.name }}
    }
  }
</div>

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AppRoute } from '../app-route';

export interface RouteStateModel {
  lastVisitedRoute: AppRoute;
}

export class SetLastVisitedRoute {
  static readonly type = '[RouteState] SetLastVisitedRoute';
  constructor(public route: AppRoute) {}
}

@State<RouteStateModel>({
  name: 'routeState',
  defaults: {
    lastVisitedRoute: AppRoute.MapView,
  },
})
@Injectable()
export class RouteState {
  @Selector() static lastVisitedRoute(state: RouteStateModel) {
    return state.lastVisitedRoute;
  }

  @Action(SetLastVisitedRoute)
  setLastVisitedRoute(ctx: StateContext<RouteStateModel>, { route }: SetLastVisitedRoute) {
    ctx.patchState({ lastVisitedRoute: route });
  }
}
